import React ,{useEffect}from 'react';
import styled from 'styled-components';
import { RideTable } from '../components/rideTable';
import { BookingTable } from '../components/bookingTable';
import Navigation from '../components/nav';
import {
  useFetchAcceptedPaidRidesQuery,
  useFetchIncomingPaidRidesQuery,
  useFetchAcceptedFreeRidesQuery,
  useFetchIncomingFreeRidesQuery
} from '../store/apiSlice';
import { AcceptedRideTable } from '../components/acceptedRideTable';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: whitesmoke;
  color: #000; /* Black text color */
`;

export const HomePage = () => {
  // Use the generated query hook
  const { data: incomingPaidRides, error: incomingPaidRidesError, isLoading,refetch:refetchPaid } = useFetchIncomingPaidRidesQuery();
  const { data: acceptedPaidRides, error: acceptedPaidRidesError, isLoading: incomingLoad } = useFetchAcceptedPaidRidesQuery();
  const { data: incomingFreeRides, error: incomingFreeRidesError, isLoading: FreeLoad,refetch:refetchFree } = useFetchIncomingFreeRidesQuery();
  const { data: acceptedFreeRides, error: acceptedFreeRidesError, isLoading: acceptedLoad } = useFetchAcceptedFreeRidesQuery();
  






  useEffect(() => {
    const intervalId = setInterval(() => {
      // Trigger the refetch function to fetch data every 10 seconds
      refetchPaid();
      refetchFree();
    }, 10000); // 10 seconds in milliseconds

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [refetchPaid,refetchFree]);











  if (incomingPaidRidesError) {
    console.error('Error fetching paid rides:', incomingPaidRides);
  }
  if (acceptedPaidRidesError) {
    console.error('Error fetching paid rides:', incomingPaidRides);
  }

  return (
    <Container>
      <Navigation />
      <h1>Incoming Paid Rides</h1>
      <RideTable title="Incoming" rides={incomingPaidRides} isLoading={isLoading} />
      <h1>Accepted Paid Rides</h1>
      <AcceptedRideTable title="Accepted" rides={acceptedPaidRides} isLoading={incomingLoad} />

      <h1>Incoming Medicaid Rides</h1>
      <BookingTable title="Incoming" rides={incomingFreeRides} isLoading={FreeLoad} showAccept={true} />
      <h1>Accepted Medicaid Rides</h1>
      <BookingTable title="Accepted" rides={acceptedFreeRides} isLoading={acceptedLoad} showAccept={false} />

    </Container>
  );
};