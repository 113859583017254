import React from 'react';

import { Login } from '../components/loginForm';



export const LoginPage = () => {
  return (
    <>
      <Login/>
    </>
  );
};


