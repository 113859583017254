import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiSlice = createApi({
    reducerPath: "api",
    baseQuery: fetchBaseQuery({
        baseUrl: "https://lightride.site/",
        prepareHeaders: (headers) => {
            const token = sessionStorage.getItem("token");
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    tagTypes: ["PaidRides", "FreeRide"],
    endpoints: (builder) => ({
        
        fetchAllPaidRides: builder.query({
            query: () => "admin/rides",
            providesTags: ["PaidRides"],
        }),


        searchPaidRides: builder.query({
            query: ({param,page,size}) => ({
              url: `admin/rides/searchRides`,
              method: "GET",
              params:{param,page,size}
            }),
            invalidatesTags: ["PaidRides"],
          }),

 
        
        fetchIncomingPaidRides: builder.query({
            query: () => "admin/rides/status/INCOMING",
            providesTags: ["PaidRides"],
        }),

        fetchAcceptedPaidRides: builder.query({
            query: () => "admin/rides/status/ACCEPTED",
            providesTags: ["PaidRides"],
        }),

        addPaidRide: builder.mutation({
            query: (ride) => ({
                url: "rides", // Update the URL according to your API
                method: "POST",
                body: ride,
            }),
            invalidatesTags: ["PaidRides"],
        }),
        updatePaidRide: builder.mutation({
            query: ({ rideId, status }) => ({
                url: `admin/rides/change-status/${rideId}/${status}`,
                method: "PUT",
            }),
            invalidatesTags: ["PaidRides"],
        }),
        deletePaidRide: builder.mutation({
            query: (id) => ({
                url: `rides/${id}`, // Update the URL according to your API
                method: "DELETE",
            }),
            invalidatesTags: ["PaidRides"],
        }),

        fetchAllFreeRides: builder.query({
            query: () => "admin/bookings",
            providesTags: ["FreeRides"],
        }),

        searchFreeRides: builder.query({
            query: ({param,page,size}) => ({
              url: `admin/bookings/searchBookings`,
              method: "GET",
              params:{param,page,size}
            }),
            invalidatesTags: ["FreeRides"],
          }),

        fetchIncomingFreeRides: builder.query({
            query: () => "admin/bookings/status/INCOMING", // Update the URL according to your API
            providesTags: ["FreeRides"],
        }),

        fetchAcceptedFreeRides: builder.query({
            query: () => "admin/bookings/status/ACCEPTED", // Update the URL according to your API
            providesTags: ["FreeRides"],
        }),

        addFreeRide: builder.mutation({
            query: (ride) => ({
                url: "free-rides", // Update the URL according to your API
                method: "POST",
                body: ride,
            }),
            invalidatesTags: ["FreeRides"],
        }),

        updateFreeRide: builder.mutation({
            query: ({ rideId, status }) => ({
                url: `admin/bookings/change-status/${rideId}/${status}`, // Update the URL according to your API
                method: "PUT",
            }),
            invalidatesTags: ["FreeRides"],
        }),

        deleteFreeRide: builder.mutation({
            query: (id) => ({
                url: `free-rides/${id}`, // Update the URL according to your API
                method: "DELETE",
            }),
            invalidatesTags: ["FreeRides"],
        }),


    }),
});

export const {
    useFetchIncomingPaidRidesQuery,
    useFetchAcceptedPaidRidesQuery,
    useAddPaidRideMutation,
    useUpdatePaidRideMutation,
    useDeletePaidRideMutation,
    useFetchIncomingFreeRidesQuery,
    useFetchAcceptedFreeRidesQuery,
    useAddFreeRideMutation,
    useUpdateFreeRideMutation,
    useDeleteFreeRideMutation,
    useFetchAllFreeRidesQuery,
    useFetchAllPaidRidesQuery,
    useSearchFreeRidesQuery,
    useSearchPaidRidesQuery
} = apiSlice;