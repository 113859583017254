import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import qs from 'qs';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Spinner,
  FormFeedback
} from "reactstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { constants} from '../../common';
import { useStore } from "../../store/dataStore";

const LoginPageContainer = styled.div`
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const StyledCard = styled(Card)`
  border: none;
  background-color: #3cb043;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`;

const StyledCardBody = styled(CardBody)`
  padding: 2rem;
`;

const StyledCardTitle = styled(CardTitle)`
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 2rem;
  color: #fff;
`;

const StyledCardText = styled(CardText)`
  text-align: center;
  margin-bottom: 2rem;
  color: #fff;
`;

const StyledFormGroup = styled(FormGroup)`
  margin-bottom: 1.5rem;
`;

const StyledLabel = styled(Label)`
  font-weight: bold;
  color: #fff;
`;


const FormContainer = styled.form`
  max-width: 400px;
  margin: auto;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const ErrorText = styled(FormFeedback)`
  display: block;
`;
const CenterDiv=styled.div`
display:flex;
align-items:center;
justify-content:center;

`;

const StyledButton = styled(Button)`
  border-radius: 0.5rem;
  padding: 0.75rem 1.5rem;
  background-color: #007bff;
  border-color: #007bff;
  font-weight: bold;

  &:hover {
    background-color: #0069d9;
    border-color: #0062cc;
  }
`;

export const Login = () => {
  const { handleSubmit, control, formState: { errors } } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const logIn = useStore((state) => state.logIn);
  

    const onSubmit =(authData) => {

        axios({
             method: 'POST',
             url: `${constants.baseUrl}login`,
             data: qs.stringify({
                 username: `${authData.email}`,
                 password: `${authData.password}`}),
   headers: {
     'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
         }}).then(response=>{
               if(response.status==200){
                   const {access_token} = response.data;
                   sessionStorage.setItem('token',access_token)
                    navigate('/Home',{ replace: true,
                   });
                   logIn();
                    
                 }}).catch(err=>alert("username or password incorrect"));
                 
                 
       };

  if (isLoading) {
    return 
       <Spinner color="primary" />
    
  }

  return (
    <LoginPageContainer>
      <StyledCard>
        <StyledCardBody>
          <StyledCardTitle>Login</StyledCardTitle>
          <StyledCardText>Welcome back! Please login to your account.</StyledCardText>
          <FormContainer onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup>
                        <Label for="email">Email</Label>
                        <Controller
                            name="email"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'Email is required',
                                pattern: {
                                    value: /^\S+@\S+$/i,
                                    message: 'Invalid email address',
                                },
                            }}
                            render={({ field }) => (
                                <>
                                    <StyledInput
                                        type="email"
                                        id="email"
                                        placeholder="Your Email"
                                        {...field}
                                        invalid={!!errors.email} // Add the invalid prop based on the errors
                                    />
                                    <ErrorText>{errors.email?.message}</ErrorText>
                                </>
                            )}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="password">Password</Label>
                        <Controller
                            name="password"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'Password is required',
                                minLength: {
                                    value: 6,
                                    message: 'Password must be at least 6 characters long',
                                },
                            }}
                            render={({ field }) => (
                                <>
                                    <StyledInput
                                        type="password"
                                        id="password"
                                        placeholder="Your Password"
                                        {...field}
                                        invalid={!!errors.password} // Add the invalid prop based on the errors
                                    />
                                    <ErrorText>{errors.password?.message}</ErrorText>
                                </>
                            )}
                        />
                    </FormGroup>

                    <Button color="primary" type="submit">
                        Sign In
                    </Button>
                </FormContainer>
        </StyledCardBody>
      </StyledCard>
    </LoginPageContainer>
  );
}




