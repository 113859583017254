import { Outlet } from "react-router";
import { useStore } from "../../store/dataStore";
import { LoginPage } from "../../pages/LoginPage";

const useAuth = () => {
  return useStore(state => state.user);
};

const ProtectedRoutes = () => {
  const isAuth = useAuth();
  return isAuth ? <Outlet /> : <LoginPage />;
};

export default ProtectedRoutes;