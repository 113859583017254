import React from 'react';
import { Table, Spinner, Button } from 'reactstrap';
import { useUpdateFreeRideMutation } from '../../store/apiSlice'; 
import styled from 'styled-components';



const StyledTable = styled(Table)`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
  border: 2px solid #000;
  border-radius: 8px;
`;

const StyledTh = styled.th`
  background-color: #000;
  color: #fff;
`;

const StyledTd = styled.td`
  background-color: #fff;
  color: #000;
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; /* Set a fixed height to center the loading spinner */
`;







export const BookingTable = ({ title,rides, onCancel, isLoading,isError,showAccept }) => {
  
  const [updateRideStatus, updateRideStatusResponse] = useUpdateFreeRideMutation();

  const handleAccept = async (rideId) => {
    try {
      await updateRideStatus({ rideId, status: 'ACCEPTED' });
      // Optionally, you can refetch the data here if needed
    } catch (error) {
      console.error('Error accepting ride:', error);
    }
  };

  const handleCancel = async (rideId) => {
    try {
      await updateRideStatus({ rideId, status: 'CANCELLED' });
      // Optionally, you can refetch the data here if needed
    } catch (error) {
      console.error('Error cancelling ride:', error);
    }
  };

  if (isLoading) {
    return <Spinner color="primary" />;
  }

  if (isError) {
    return <p>Error fetching ride data.</p>;
  }

  if (!rides || rides.length === 0) {
    return <p>No rides available.</p>;
  }


  return (
    <StyledTable striped bordered hover>
      <thead>
        <tr>
          <th>ID</th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Date of Birth</th>
          <th>Gender</th>
          <th>Medical Number</th>
          <th>Phone Number</th>
          <th>Email</th>
          <th>Pickup Location</th>
          <th>Dropoff Location</th>
          <th>Appointment Date</th>
          <th>Appointment Time</th>
          <th>Ride Type</th>
          <th>Round Trip</th>
          <th>Special Notes</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {rides.map((ride) => (
          <tr key={ride.id}>
            <td>{ride.id}</td>
            <td>{ride.firstName}</td>
            <td>{ride.lastName}</td>
            <td>{ride.dateOfBirth}</td>
            <td>{ride.gender}</td>
            <td>{ride.medicalNumber}</td>
            <td>{ride.phoneNumber}</td>
            <td>{ride.email}</td>
            <td>{ride.pickupLocation}</td>
            <td>{ride.dropoffLocation}</td>
            <StyledTd>{new Date(ride.appointmentDate).toLocaleString().substring(0,10)}</StyledTd>
            <StyledTd>{ride.appointmentTime}</StyledTd>
            <td>{ride.rideType}</td>
            <td>{ride.roundTrip.toString()}</td>
            <td>{ride.specialNotes}</td>
            <td>
               <ActionContainer>
                {showAccept && 
              <Button color="success" onClick={() => handleAccept(ride.id)}>
                Accept
              </Button>
                 }
              <Button color="danger" onClick={() => handleCancel(ride.id)}>
                Cancel
              </Button>
              </ActionContainer>
            </td>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
};


