// Navigation.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const StyledNavbar = styled(Navbar)`
  background-color: #3498db;
`;

const StyledLink = styled(Link)`
  color: #fff;
  text-decoration: none;

  &:hover {
    color: #fff;
    text-decoration: none;
  }
`;

const Navigation = () => {
  const navigate=useNavigate();



  return (
    <StyledNavbar color="dark" dark expand="md">
      <NavbarBrand>
        <StyledLink to="/home">Light Ride</StyledLink>
      </NavbarBrand>
      <Nav className="ml-auto" navbar>
        <NavItem>
          <NavLink>
            <StyledLink  to="/AllBookings">All Bookings</StyledLink>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink>
            <StyledLink to="/AllRides">All Rides</StyledLink>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink>
            <StyledLink to="/settings">Settings</StyledLink>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink>
            <StyledLink to="/">LogOut</StyledLink>
          </NavLink>
        </NavItem>
      </Nav>
    </StyledNavbar>
  );
};

export default Navigation;
