import React, { useState, useEffect } from 'react';
import { useSearchFreeRidesQuery } from '../../store/apiSlice';
import { Spinner, Input, Table } from 'reactstrap';
import styled from 'styled-components';
import ReactPaginate from "react-paginate";

const Container = styled.div`
  margin: 20px;
`;

const SearchBar = styled(Input)`
  width: 300px;
  margin-bottom: 20px;
`;

const BookingTable = styled(Table)`
  width: 100%;
`;

export const AllBookings = () => {

  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);


  const {
    data: searchResults,
    isLoading: searchLoading,
    isError: searchError,
  } = useSearchFreeRidesQuery({
    param: searchTerm,
    page: currentPage,
    size: pageSize
  });



  const totalPages = searchResults?.totalPages || 0;
  const pageCount = Math.ceil(searchResults?.totalElements / pageSize);


  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };




  if (searchLoading) {
    return <Spinner color="primary" />;
  }

  if (searchError) {
    return <p>Error fetching booking data.</p>;
  }

  return (
    <Container>
      <h1>All Bookings</h1>
      <SearchBar
        type="text"
        placeholder="Search by phone,name,medicaid number"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <BookingTable striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Phone</th>
            <th>Gender</th>
            <th>Medical Number</th>
            <th>Email</th>
            <th>Appointment Date</th>
            <th>Appointment Time</th>
            <th>Pickup Location</th>
            <th>Drop-off Location</th>
            <th>Ride Type</th>
            <th>Round Trip</th>
            <th>Ride Booking Date</th>
            <th>Special Notes</th>
            <th>Status</th>
            {/* Add more columns based on your booking data */}
          </tr>
        </thead>
        <tbody>
          {searchResults?.content.map((booking) => (
            <tr key={booking.id}>
              <td>{booking.id}</td>
              <td>{`${booking.firstName} ${booking.lastName}`}</td>
              <td>{booking.phoneNumber}</td>
              <td>{booking.gender}</td>
              <td>{booking.medicalNumber}</td>
              <td>{booking.email}</td>
              <td>{new Date(booking.appointmentDate).toLocaleString().substring(0, 10)}</td>
              <td>{booking.appointmentTime}</td>
              <td>{booking.pickupLocation}</td>
              <td>{booking.dropoffLocation}</td>
              <td>{booking.rideType}</td>
              <td>{booking.roundTrip.toString()}</td>
              <td>{new Date(booking.rideBookingDate).toLocaleString()}</td>
              <td>{booking.specialNotes}</td>
              <td>{booking.status}</td>
              {/* Add more cells based on your booking data */}
            </tr>
          ))}
        </tbody>
      </BookingTable>
      <ReactPaginate
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageChange}
        containerClassName="pagination"
        activeClassName="active"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakClassName="page-item disabled"
        breakLinkClassName="page-link"
        disabledClassName="disabled"
        previousLabel="Previous"
        nextLabel="Next"
        breakLabel="..."
      />
    </Container>
  );
};