import React, { useEffect } from 'react';
import styled from 'styled-components';
import Navigation from '../components/nav';

import { AllRides } from '../components/allRides';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: whitesmoke;
  color: #000; /* Black text color */
`;



export const AllRidesPage = () => {
  // Use the generated query hook
  return (
    <Container>
      <Navigation />
      <AllRides />

    </Container>
  );
};