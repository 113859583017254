import React, { useEffect } from 'react';
import styled from 'styled-components';
import Navigation from '../components/nav';
import { AllBookings } from '../components/allBookings';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: whitesmoke;
  color: #000; /* Black text color */
`;



export const AllBookingsPage = () => {
  // Use the generated query hook
  return (
    <Container>
        <Navigation/>
      <AllBookings/>
    </Container>
  );
};