import React, { useState, useEffect } from 'react';
import { useSearchPaidRidesQuery } from '../../store/apiSlice'; 
import { Spinner, Input, Table } from 'reactstrap';
import styled from 'styled-components';
import ReactPaginate from "react-paginate";

const Container = styled.div`
  margin: 20px;
`;

const SearchBar = styled(Input)`
  width: 300px;
  margin-bottom: 20px;
`;

const RideTable = styled(Table)`
  width: 100%;
`;

export const AllRides = () => {
  
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [searchDate, setSearchDate] = useState('');
  const [pageSize, setPageSize] = useState(5);
  

  const {
    data: searchResults,
    isLoading: searchLoading,
    isError: searchError,
  } = useSearchPaidRidesQuery({
    param: searchTerm,
    page:currentPage,
    size:pageSize
  });

  const filteredResults = searchResults?.content.filter(
    (ride) =>
      !searchDate ||
      new Date(ride.appointmentDate).toLocaleDateString() ===
        new Date(searchDate).toLocaleDateString()
  );

  const totalPages = filteredResults?.length || 0;
  const pageCount = Math.ceil(totalPages / pageSize);


  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };



  
  if (searchLoading) {
    return <Spinner color="primary" />;
  }

  if (searchError) {
    return <p>Error fetching booking data.</p>;
  }


  return (
    <Container>
      <h1>All Rides</h1>
      <SearchBar
        type="text"
        placeholder="Search by phone or name"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <SearchBar
        type="date"
        placeholder="Filter by date"
        value={searchDate}
        onChange={(e) => setSearchDate(e.target.value)}
      />
      <RideTable striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Phone</th>
            <th>Pickup Location</th>
            <th>Drop-off Location</th>
            <th>Ride Date</th>
            <th>Appointment Time</th>
            <th>Specific Requirements</th>
            <th>Fare Amount</th>
            <th>Ride Booking Date</th>
            <th>Ride Type</th>
          <th>Round Trip</th>
            <th>Distance</th>
            <th>Status</th>
            {/* Add more columns based on your ride data */}
          </tr>
        </thead>
        <tbody>
        {filteredResults
            ?.slice(currentPage * pageSize, (currentPage + 1) * pageSize)
            .map((ride) => (
            <tr key={ride.id}>
              <td>{ride.id}</td>
              <td>{`${ride.firstName} ${ride.lastName}`}</td>
              <td>{ride.phone}</td>
              <td>{ride.pickupLocation}</td>
              <td>{ride.dropoffLocation}</td>
              <td>{new Date(ride.appointmentDate).toLocaleString().substring(0,10)}</td>
              <td>{ride.appointmentTime}</td>
              <td>{ride.specificRequirements}</td>
              <td>{ride.fareAmount}</td>
              <td>{new Date(ride.rideBookingDate).toLocaleString()}</td>
              <td>{ride.rideType}</td>
            <td>{ride.roundTrip.toString()}</td>
              <td>{ride.distance}</td>
              <td>{ride.status}</td>
              {/* Add more cells based on your ride data */}
            </tr>
          ))}
        </tbody>
      </RideTable>
      <ReactPaginate
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageChange}
        containerClassName="pagination"
        activeClassName="active"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakClassName="page-item disabled"
        breakLinkClassName="page-link"
        disabledClassName="disabled"
        previousLabel="Previous"
        nextLabel="Next"
        breakLabel="..."
      />
    </Container>
  );
};