import React from 'react';
import { Table, Button, Spinner } from 'reactstrap';
import styled from 'styled-components';
import { useUpdatePaidRideMutation } from '../../store/apiSlice';

const StyledTable = styled(Table)`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
  border: 2px solid #000;
  border-radius: 8px;
`;

const StyledTh = styled.th`
  background-color: #000;
  color: #fff;
`;

const StyledTd = styled.td`
  background-color: #fff;
  color: #000;
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; /* Set a fixed height to center the loading spinner */
`;

export const RideTable = ({ title,rides, onCancel, isLoading }) => {

  const [updateAccept, updateAcceptResponse] = useUpdatePaidRideMutation();

  const handleAccept = async (rideId) => {
    try {
      
      const response = await updateAccept({ rideId: rideId, status: 'ACCEPTED' });
      // Handle the mutation response if needed
    } catch (error) {
      console.error('Error accepting ride:', error);
    }
  };


  const handleCancel = async (rideId) => {
    try {
      const response = await updateAccept({ rideId: rideId, status: 'CANCELLED' });
      // Handle the mutation response if needed
    } catch (error) {
      console.error('Error accepting ride:', error);
    }
  };



  if (isLoading) {
    return (
      <LoadingContainer>
        <Spinner color="primary" />
      </LoadingContainer>
    );
  }

  if (!rides || rides.length === 0) {
    return <p>No {title} rides.</p>;
  }

  return (
    <StyledTable striped bordered hover>
      <thead>
        <tr>
          <StyledTh>ID</StyledTh>
          <StyledTh>Name</StyledTh>
          <StyledTh>Phone</StyledTh>
          <StyledTh>Pickup Location</StyledTh>
          <StyledTh>Drop-off Location</StyledTh>
          <StyledTh>Ride Date</StyledTh>
          <StyledTh>Ride Time</StyledTh>         
          <StyledTh>Distance</StyledTh>
          <StyledTh>Ride Type</StyledTh>
          <StyledTh>Round Trip</StyledTh>
          <StyledTh>Actions</StyledTh>
        </tr>
      </thead>
      <tbody>
        {rides.map((ride) => (
          <tr key={ride.id}>
            <StyledTd>{ride.id}</StyledTd>
            <StyledTd>{`${ride.firstName} ${ride.lastName}`}</StyledTd>
            <StyledTd>{ride.phone}</StyledTd>
            <StyledTd>{ride.pickupLocation}</StyledTd>
            <StyledTd>{ride.dropoffLocation}</StyledTd>
            <StyledTd>{new Date(ride.appointmentDate).toLocaleString().substring(0,10)}</StyledTd>
            <StyledTd>{ride.appointmentTime}</StyledTd>
            <StyledTd>{ride.distance}</StyledTd>
            <StyledTd>{ride.rideType}</StyledTd>
            <StyledTd>{ride.roundTrip.toString()}</StyledTd>
            <StyledTd>
              <ActionContainer>
                <Button color="success" onClick={() => handleAccept(ride.id)}>
                  Accept
                </Button>
                <Button color="danger" onClick={() => handleCancel(ride.id)}>
                  Cancel
                </Button>
              </ActionContainer>
            </StyledTd>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
};
