import React from 'react';
import './App.css';
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import { LoginPage } from './pages/LoginPage';
import { HomePage } from './pages/HomePage';
import { AllRidesPage } from './pages/AllRidesPage';
import { AllBookingsPage } from './pages/AllBookingsPage';
import { SettingsPage } from './pages/SettingsPage';
import ProtectedRoutes from './components/protectedRoutes';




function App() {
  return (
    <>
    <Router>
     <Routes>
        <Route path='/' exact={true} element={<LoginPage/>} />
        <Route element={<ProtectedRoutes />}>
        <Route path='/home' exact={true} element={<HomePage/>} />
        <Route path='/AllRides' exact={true} element={<AllRidesPage/>} />
        <Route path='/AllBookings' exact={true} element={<AllBookingsPage/>} />
        <Route path='/settings' exact={true} element={<SettingsPage/>} />
        </Route>
        </Routes>
    </Router>
    </>
  );
}

export default App;
