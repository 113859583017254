import create from 'zustand';
import { persist } from "zustand/middleware"



export const useStore = create(persist(set => ({
    logIn: () => set(state => ({ user: true })),
    removeAll: () => set(state=>({user: false }))
    }),{name:"use",
    getStorage:()=>sessionStorage,}),
    )