import React, { useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Button, Form, FormGroup, Label, Input, Spinner } from 'reactstrap';
import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import { constants } from '../../common';

const StyledContainer = styled(Container)`
  margin-top: 50px;
`;

export const Settings = () => {
  const { control, handleSubmit, formState: { errors }, reset } = useForm();
  const [isSuspended, setIsSuspended] = React.useState(null);
  const [suspendStatus, setSuspendStatus] = React.useState(''); // 'success', 'error', or ''
  const token = sessionStorage.getItem('token');


  const handleSuspendToggle = async () => {
    try {
      
  
      const response = !isSuspended
        ? await axios.put(`${constants.baseUrl}admin/rides/suspend-endpoint`, {}, { headers: { Authorization: `Bearer ${token}` } })
        : await axios.put(`${constants.baseUrl}admin/rides/unsuspend-endpoint`, {}, { headers: { Authorization: `Bearer ${token}` } });
  
      if (response.status === 200) {
        setIsSuspended(!isSuspended);
        setSuspendStatus('success');
      } else {
        setSuspendStatus('error');
      }
    } catch (error) {
      console.error('Error toggling suspension:', error);
      setSuspendStatus('error');
    }
  };
  
  const handleChangePassword = async (data) => {
    try {
      const response = await axios.patch(
        `${constants.baseUrl}users/change-password`,
        null, // Pass null as the second argument for a PATCH request
        {
          params: { oldPassword: data.oldPassword, newPassword: data.newPassword },
          headers: { Authorization: `Bearer ${token}` },
        }
      );
  
      if (response.status === 200) {
        reset();
        alert('Password changed successfully');
      } else {
        alert('Error changing password:', response.data.message);
      }
    } catch (error) {
      console.error('Error changing password:', error);
    }
  };
  
  // Fetch initial suspension status
  useEffect(() => {
    const fetchSuspendStatus = async () => {
      try {
        const response = await axios.get(`${constants.baseUrl}rides/bookstatus`);
        setIsSuspended(response.data);
      } catch (error) {
        console.error('Error fetching suspension status:', error);
      }
    };
  
    fetchSuspendStatus();
  }, []);

  return (
    <StyledContainer>
      <h1>Settings</h1>
      <Row>
        <Col md={6}>
          {suspendStatus && (
            <div style={{ marginTop: '10px', color: suspendStatus === 'success' ? 'green' : 'red' }}>
              {suspendStatus === 'success' ? 'Operation Done Successfully' : 'Error Suspending Service'}
            </div>
          )}
          <Button color="primary" onClick={handleSuspendToggle}>
            {isSuspended ? 'Activate Service' : 'Suspend Service'}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <h3>Change Password</h3>
          <Form onSubmit={handleSubmit(handleChangePassword)}>
            <FormGroup>
              <Label for="oldPassword">Old Password</Label>
              <Controller
                name="oldPassword"
                control={control}
                defaultValue=""
                rules={{ required: 'Old Password is required' }}
                render={({ field }) => <Input type="password" id="oldPassword" {...field} />}
              />
            </FormGroup>
            <FormGroup>
              <Label for="newPassword">New Password</Label>
              <Controller
                name="newPassword"
                control={control}
                defaultValue=""
                rules={{ required: 'New Password is required' }}
                render={({ field }) => <Input type="password" id="newPassword" {...field} />}
              />
            </FormGroup>
            <Button type="submit" color="primary">
              Change Password
            </Button>
            {errors.oldPassword && (
              <div style={{ color: 'red', marginTop: '10px' }}>{errors.oldPassword.message}</div>
            )}
            {errors.newPassword && (
              <div style={{ color: 'red', marginTop: '10px' }}>{errors.newPassword.message}</div>
            )}
          </Form>
        </Col>
      </Row>
    </StyledContainer>
  );
};


